@import url('https://fonts.googleapis.com/css?family=VT323');

.overall{
  display: flex;
  flex-direction: column;
 height: 100vh;
  width:100vw;
  overflow-x: hidden;
  align-items: center;
  /* background-color: #F6F6F2; */
background: white;

}
.call {
    font-family: 'Raleway', sans-serif;
    /* background: #222629; */
    background: #242424;
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .call1 {
    font-family: 'Raleway', sans-serif;
    /* background: green;    */
    color: #aaa;
    background-color: WHITE;
  }

  .call2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    color: #aaa;
    margin: 10px 0 50px 0;
    
  }  

  .contain{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* minHeight: 70vh; */
    width: 80vw;
  }
  
  .heading{
    font-family: Raleway;
    font-size: 3vmax;
    color: #3d3d3d;
    font-weight:600;
    text-transform: uppercase;
    position: relative;
}

.downlast{
  border-top: 1px solid grey;
  padding:1vmax 0 1.2vmax 0 ;
  font-size: 1.1vmax;
  display: flex;
  justify-content: center;
  width: 70vw;
}
.project{
 
    display: flex;
    /* flex-direction: column; */
    align-content: center;
    align-items: center;
    margin: 1.4vmax 0.7vmax 0 0.7vmax;
    width: 21vmax;
    height: 33vmax;
  
}

  .contact{
    width:70vw;
  }

  .rec1{
    transform: rotate(20deg);
      position: relative;
      left: -57%;
      z-index: 1;
      size: 100;
      height: 14vmax;
  }

@media all and (max-width:600px){
  .project{
    margin: 1.4vmax 0.7vmax 0 0.7vmax;
    width: 77vw;
    height: 30vh;
    padding-bottom:15vw;
  
}

.heading{
  font-size: 10vw;
  margin: 0;
  z-index: 2;
}
.rec1{
  transform: rotate(20deg);
    height: 36vw;
    left: -58%;
}
.contact{
  width: 77vw;
}
.downlast{
  padding:2vmax 0 2.2vmax 0 ;
  font-size: 4vw;
  width: 77vw;
}

}