@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=VT323);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=VT323);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
 

body {
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
  }
  
h1 { font-size: 34px;
    color: #f8f8f8; 
  font-weight: 400;
  margin: 20px 0 14px 0;}

p {
    color: #646464;
    font-size: 22px;
    margin: 0;
    font-weight:180;
  }

  img{
    height: 120px;
  }
  /* p2 {
    color: #949494;
    color: #20b8ff;
   
  } */
  
  .first{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh);
    width:100vw;
    
  }

  #cursor {
    border-left: .05em solid ;
    -webkit-animation: blink .7s steps(1) infinite;
            animation: blink .7s steps(1) infinite;
  }
  
  @-webkit-keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  @media all and (max-width:600px){
  
    .first{
    
      height: calc(100vh + 60px);
      
    }
  }
/* body{
    display:inline;
} */

.container{
    width:70vw;
    margin: 12.5vmax 0 12.5vmax 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.about{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    minHeight: 70vh;
    width: 100%;
  }

  .aboutH{
    display: flex;
    align-content: center;
    align-items: center;
  }

.para {
    color: #646464;
    font-size: 1.3vmax;
    margin: 0;
    font-weight:300;
    font-family: 'Open Sans';
    /* display:inline; */
  }


.image{
  height: 20vmax;
  margin-left: 40px;
  position: -webkit-sticky;
  position: sticky;
  /* order: 2; */
}
.image2{
  height: 20vmax;
  margin-left: 40px;
  position: -webkit-sticky;
  position: sticky;
  display: none;/* order: 2; */
}

.mobileContainer{
  order: -1;
}
.rec{
  -webkit-transform: rotate(12deg);
          transform: rotate(12deg);
    position: relative;
    /* top: -4%; */
    left:-40%;
    z-index: 1;
    size: 100;
    height: 6vmax;
}


@media all and (max-width:600px){

  .container{
    display: flex;
    flex-direction: column;
    width:100vw;
    margin:  00px 5vw 100px 5vw ;
  }

  .image{
    height: 250px;
    margin-right: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: relative;
    top: -43px;
    display: none;
  
  }
  .image2{
    height: 250px;
    margin-right: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: relative;
    top: -43px;
    display: inline;
  
  }

  .imageContainer{
    margin:-50px;
  }

  .mobileContainer{
    padding: 0 5vw 0 5vw;
    order: 2;

  }
  .para{
    font-size:17px;
  }
  .rec{
      left:-56%;
      height:21vw;
  }
  .aboutH{
    margin-bottom: 6vw;
  }
}


.cContainer{
    display: flex;
    align-items: stretch;
    margin: 10vmax 0 10vmax 0;
    justify-content: center;
    font-family: Raleway;
}

.cRight{
    display: flex;
    flex-direction: column;
    margin: 0 0 0 5vmax;
}
.cLeft{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    align-content: center;
    /* height: 100%; */
}

.cHead{
    font-size: 1.8vmax;
    margin: 3vmax 0 0.6vmax 0;
    font-weight: bold;
}


.cContent{
    
    margin: 0.6vmax 3vmax 0.6vmax 0;
    font-size: 1.4vmax;
}

a{
    text-decoration: none;
    color: #646464;

}
a:hover{
    -webkit-text-decoration-color: #646464;
            text-decoration-color: #646464;
}
a:visited{
    color: #646464;
}

.rec2{
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
      position: relative;
      left: -19%;
      z-index: 1;
      height: 11vmax;
  }

.leftH{
    left:34%;
}

@media all and (max-width:600px){

    .cContainer{
        flex-direction: column;
    }
    .cRight{
        margin:0;
    }
    .cHead{
        font-size: 5vw;
    }
    .cContent{
        font-size: 4vw;
    }
    .rec2{
        left: -57%;
    height: 17vmax;
    }
    .leftH{
        left:0;
    }
    .cLeft{
        padding-bottom: 6vw;
    }
}


.card{
  width: 21vmax;
  height: 33.2vmax;
    display: flex;
    flex-direction: column;
    margin: 1.4vmax 0.7vmax 0 0.7vmax;
    align-items: flex-start;
    box-shadow: 0 1px 7px 2px rgba(10,16,34,.08);
    border-radius: 8px;
    
}

.img{
  height: 21vmax ;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  margin: 0;
  border-radius: 8px 8px 0 0;
}

.middle{
  width: 100%;
  padding: 0 5% 0 5%;
}

.projectName{
  color: #3d3d3d;
  margin: 0.8vmax 0 0vmax 0;
  font-family: Raleway;
  font-size: 1.9vmax;
  font-weight:600;
}

.projectDesc{
  font-size: 1.1vmax;
  font-family: 'Open Sans';
  margin-top: 0.35vmax;
}

.date{
  font-family: 'Open Sans';
  font-size: 1vmax;
  margin-bottom: 8px;
  color:#7d7968;
}

a:hover {
  cursor:pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: #3d3d3d;
          text-decoration-color: #3d3d3d;
 }
a{
  text-decoration: none;
}

@media all and (max-width:600px){

  .container{
    display: flex;
    flex-direction: column;
    width:100vw;
    margin:  00px 5vw 100px 5vw ;
  }

  .image{
    height: 250px;
    margin-right: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: relative;
    top: -43px;
  
  }

  .imageContainer{
    margin:-50px;
  }
  
  .card{
    width:77vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 5vw 0.7vmax 0 0.7vmax;
    padding-bottom: 4vw;
  }
  .img{
    height:77vw;
  }

  .projectName{
    margin: 0.8vmax 0 0.4vmax 0;
    font-size: 7.5vw;
    font-weight:600;
  }
  
  .projectDesc{
    font-size: 4.2vw;
    margin-top: 0.35vmax;
  }
  
  .date{
    font-size: 3.2vw;
    margin-bottom: 8px;
  }

}




.overall{
  display: flex;
  flex-direction: column;
 height: 100vh;
  width:100vw;
  overflow-x: hidden;
  align-items: center;
  /* background-color: #F6F6F2; */
background: white;

}
.call {
    font-family: 'Raleway', sans-serif;
    /* background: #222629; */
    background: #242424;
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .call1 {
    font-family: 'Raleway', sans-serif;
    /* background: green;    */
    color: #aaa;
    background-color: WHITE;
  }

  .call2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    color: #aaa;
    margin: 10px 0 50px 0;
    
  }  

  .contain{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* minHeight: 70vh; */
    width: 80vw;
  }
  
  .heading{
    font-family: Raleway;
    font-size: 3vmax;
    color: #3d3d3d;
    font-weight:600;
    text-transform: uppercase;
    position: relative;
}

.downlast{
  border-top: 1px solid grey;
  padding:1vmax 0 1.2vmax 0 ;
  font-size: 1.1vmax;
  display: flex;
  justify-content: center;
  width: 70vw;
}
.project{
 
    display: flex;
    /* flex-direction: column; */
    align-content: center;
    align-items: center;
    margin: 1.4vmax 0.7vmax 0 0.7vmax;
    width: 21vmax;
    height: 33vmax;
  
}

  .contact{
    width:70vw;
  }

  .rec1{
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
      position: relative;
      left: -57%;
      z-index: 1;
      size: 100;
      height: 14vmax;
  }

@media all and (max-width:600px){
  .project{
    margin: 1.4vmax 0.7vmax 0 0.7vmax;
    width: 77vw;
    height: 30vh;
    padding-bottom:15vw;
  
}

.heading{
  font-size: 10vw;
  margin: 0;
  z-index: 2;
}
.rec1{
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
    height: 36vw;
    left: -58%;
}
.contact{
  width: 77vw;
}
.downlast{
  padding:2vmax 0 2.2vmax 0 ;
  font-size: 4vw;
  width: 77vw;
}

}
